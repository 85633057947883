<div class="container">
  <div class="d-flex align-items-center">
    <h1 class="display-4 pt-0 mb-0">Tableau de bord</h1>
  <button class="btn btn-secondary ml-5" style="height:60%;" *ngIf="navFiche !== true" (click)="onAfficheFiche()">Fiches</button>
  <button class="btn btn-secondary ml-5 " style="height:60%;" *ngIf="navContenus !== true" (click)="onAfficheContenus()">Contenus</button>
  </div>

  <hr class="mt-0 mb-5 bg-dark">
  <div class="row">
    <div class="col-12">

      <div *ngIf="fiche">
        <app-admin-list-exercice></app-admin-list-exercice>
      </div>

      <div *ngIf="contenus">
        <app-admin-contenus></app-admin-contenus>
      </div>

    </div>
  </div>

</div>
