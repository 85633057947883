import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  navFiche = true;
  navContenus = false;

  fiche = true;
  contenus = false;

  constructor() { }

  ngOnInit(): void {
  }

  onAfficheFiche(){
    this.navContenus = false;
    this.navFiche = true;
    this.contenus = false;
    this.fiche = true;
  }

  onAfficheContenus(){
    this.navFiche = false;
    this.navContenus = true;
    this.fiche = false;
    this.contenus = true ;
  }

}
