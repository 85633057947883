<div class="container mb-5 rounded shadow bg-white p-4" style="min-height: 90vh">

  <h1 class="text-center my-4">Ressources</h1>

  <p class="text-center" id="emplacement">

  </p>
  <br><br>

  <div *ngFor="let ressource of ressources; let i = index">
    <div class="row">
      <div class="col-12 d-flex">
        <h3>{{ ressource.nom }}</h3>
        <button class="ml-4 btn btn-info" (click)="onActivateLinkRessource(i)"
        *ngIf="!linkActivateRessource[i] || linkActivateRessource[i] === false">Afficher le pdf</button>

        <button class="btn btn-warning ml-4" (click)="onActivateLinkRessource(i)"
        *ngIf="linkActivateRessource[i] && linkActivateRessource[i] != false">Masquer le pdf</button>

      </div>
    </div>
    <iframe [src]="ressource.lien | safe" width="100%" height="1000px" class="mt-3"
      *ngIf="linkActivateRessource[i] && linkActivateRessource[i] != false">
    </iframe>
    <hr>
  </div>

</div>
