import { Component, OnInit } from '@angular/core';
import { Fiche } from '../interface/fiche';
import { Subscription } from 'rxjs';
import { FicheService } from '../services/fiche.service';
import { Contenus } from '../interface/contenus';
import { ContenusService } from '../services/contenus.service';

@Component({
  selector: 'app-fiches-patient',
  templateUrl: './fiches-patient.component.html',
  styleUrls: ['./fiches-patient.component.scss']
})
export class FichesPatientComponent implements OnInit {

  fichesPatient: Fiche[] = [];
  contenus: Contenus;

  fichesPatientSubscription: Subscription;
  contenusSubscription : Subscription;
  linkActivatePatient: boolean[] = [];


  constructor(private ficheService: FicheService,
              private contenusService: ContenusService) { }

  ngOnInit(): void {
    this.fichesPatientSubscription = this.ficheService.fichesPatientSubject.subscribe((data: Fiche[]) => {
      this.fichesPatient = data;
    });
    this.contenusSubscription = this.contenusService.contenusSubject.subscribe((data : Contenus)=>{
      this.contenus = data;
      if(this.contenus){
        document.getElementById("emplacement").innerHTML = this.contenus.fichePatient
        }
      })
    this.ficheService.getFichesPatient();
    this.ficheService.emitFichePatient();
    this.contenusService.getContenus();
    this.contenusService.emitContenus();
  }
  onActivateLinkPatient(i) {
    if (this.linkActivatePatient[i]) {
      this.linkActivatePatient[i] = false;
    } else {
      this.linkActivatePatient[i] = true;
    }
  }


  ngOnDestroy() {
    this.fichesPatientSubscription.unsubscribe();
    this.contenusSubscription.unsubscribe();

  }
}
