import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContenusService } from 'src/app/services/contenus.service';
import { Contenus } from 'src/app/interface/contenus';

@Component({
  selector: 'app-admin-contenus',
  templateUrl: './admin-contenus.component.html',
  styleUrls: ['./admin-contenus.component.scss']
})
export class AdminContenusComponent implements OnInit, OnDestroy {

  contenusForm: FormGroup;
  contenusSubscription: Subscription;
  contenus : Contenus;

  indexToRemove;
  indexToUpdate;
  editMode = false;

  ficheUploading = false;
  ficheUploaded = false;
  fichierUrl: string;

  constructor(private formBuilder: FormBuilder,
              private contenusService: ContenusService) { }

  ngOnInit(): void {
    this.initContenusForm();
      this.contenusSubscription = this.contenusService.contenusSubject.subscribe((data)=>{
        this.contenus = data;
        this.contenusForm.get('acceuil').setValue(this.contenus? this.contenus.acceuil : '');
        this.contenusForm.get('ficheKine').setValue(this.contenus? this.contenus.ficheKine : '');
        this.contenusForm.get('fichePatient').setValue(this.contenus? this.contenus.fichePatient : '');
        this.contenusForm.get('presentation').setValue(this.contenus? this.contenus.presentation : '');
        this.contenusForm.get('ressources').setValue(this.contenus? this.contenus.ressources : '');
      })
      this.contenusService.getContenus();
      this.contenusService.emitContenus();

}

initContenusForm() {
  this.contenusForm = this.formBuilder.group({
    acceuil: ['' , Validators.required],
    ficheKine: ['', Validators.required],
    fichePatient: ['', Validators.required],
    presentation: ['', Validators.required],
    ressources: ['', Validators.required]
  });
}

onSubmitContenus(){
  const newContenu: Contenus = this.contenusForm.value;
  this.contenusService.updateContenus(newContenu);
}

ngOnDestroy() {
  this.contenusSubscription.unsubscribe();
}


}

