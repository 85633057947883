import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators,  } from '@angular/forms';
import { FicheService } from 'src/app/services/fiche.service';
import { Subscription } from 'rxjs';
import { Fiche } from 'src/app/interface/fiche';
import * as $ from 'jquery';
import { RessourcesService } from 'src/app/services/ressources.service';

@Component({
  selector: 'app-admin-list-exercice',
  templateUrl: './admin-list-exercice.component.html',
  styleUrls: ['./admin-list-exercice.component.scss']
})
export class AdminListExerciceComponent implements OnInit, OnDestroy {

  fichePatientForm: FormGroup;
  fichePatientSubscription: Subscription;
  fichesPatient: Fiche[] = [];

  fichesKineForm:FormGroup;
  fichesKineSubscription:Subscription;
  fichesKine: Fiche[]=[];

  ressourcesForm: FormGroup;
  ressourcesSubscription : Subscription;
  ressources: Fiche[] = [];

  indexToRemove;
  indexToUpdate;
  editMode = false;

  ficheUploading = false;
  ficheUploaded = false;
  fichierUrl: string;

  linkActivatePatient : boolean[] = [];
  linkActivateKine : boolean[] = [];
  linkActivateRessource : boolean[] = [];

  constructor(private formBuilder: FormBuilder,
              private ficheService: FicheService,
              private ressourcesService : RessourcesService ) { }

  ngOnInit(): void {
    this.initFichePatientForm();
    this.initFichesKineForm();
    this.initRessourcesForm();

    this.fichesKineSubscription = this.ficheService.fichesKineSubject.subscribe((data: Fiche[]) => {
      this.fichesKine = data;
    });
    this.fichePatientSubscription = this.ficheService.fichesPatientSubject.subscribe((data: Fiche[]) => {
      this.fichesPatient = data;
    });
    this.ressourcesSubscription = this.ressourcesService.ressourcesSubject.subscribe((data: Fiche[]) => {
      this.ressources = data;
    });

    this.ficheService.getFichesKine()
    this.ficheService.getFichesPatient();
    this.ressourcesService.getRessources();

    this.ficheService.emitFicheKine();
    this.ficheService.emitFichePatient();
    this.ressourcesService.emitRessources();

  }

  initFichePatientForm() {
    this.fichePatientForm = this.formBuilder.group({
      nom: ['', Validators.required],
    });
  }

  initFichesKineForm() {
    this.fichesKineForm = this.formBuilder.group({
      nom: ['', Validators.required],
    });
  }

  initRessourcesForm() {
    this.ressourcesForm = this.formBuilder.group({
      nom: ['', Validators.required],
    });
  }

  onSubmitFichePatient() {
    const newFiche: Fiche = this.fichePatientForm.value;
    newFiche.lien = this.fichierUrl ? this.fichierUrl : '';
    if (this.editMode) {
      this.ficheService.updateFichePatient(newFiche, this.indexToUpdate);
    } else {
      this.ficheService.createFichePatient(newFiche);
    }
    $('#fichePatientFormModal').modal('hide')
  }

  onSubmitFichesKine() {
    const newFiche: Fiche = this.fichesKineForm.value;
    newFiche.lien = this.fichierUrl ? this.fichierUrl : '';
    if (this.editMode) {
      this.ficheService.updateFicheKine(newFiche, this.indexToUpdate);
    } else {
      this.ficheService.createFicheKine(newFiche);
    }
    $('#ficheKineFormModal').modal('hide')
  }

  onSubmitRessources() {
    const newRessource: Fiche = this.ressourcesForm.value;
    newRessource.lien = this.fichierUrl ? this.fichierUrl : '';
    if (this.editMode) {
      this.ressourcesService.updateRessources(newRessource, this.indexToUpdate);
    } else {
      this.ressourcesService.createRessource(newRessource);
    }
    $('#ressourceFormModal').modal('hide')
  }

  onDeleteFichePatient(index) {
    $('#deleteModalPatient').modal('show');
    this.indexToRemove = index;
  }

  onDeleteFicheKine(index) {
    $('#deleteModalKine').modal('show');
    this.indexToRemove = index;
  }

  onDeleteRessource(index) {
    $('#deleteModalRessource').modal('show');
    this.indexToRemove = index;
  }

  onConfirmDeleteFichePatient() {
    if(this.fichesPatient[this.indexToRemove].lien && this.fichesPatient[this.indexToRemove].lien !== ''){
      this.ficheService.removeFile(this.fichesPatient[this.indexToRemove].lien);
    }
    this.ficheService.deleteFichePatient(this.indexToRemove);
    $('#deleteModalPatient').modal('hide');
  }

  onConfirmDeleteFicheKine() {
    if(this.fichesKine[this.indexToRemove].lien && this.fichesKine[this.indexToRemove].lien !== ''){
      this.ficheService.removeFile(this.fichesKine[this.indexToRemove].lien);
    }
    this.ficheService.deleteFicheKine(this.indexToRemove);
    $('#deleteModalKine').modal('hide');
  }

  onConfirmDeleteRessource() {
    if(this.ressources[this.indexToRemove].lien && this.ressources[this.indexToRemove].lien !== ''){
      this.ressourcesService.removeFile(this.ressources[this.indexToRemove].lien);
    }
    this.ressourcesService.deleteRessources(this.indexToRemove);
    $('#deleteModalRessource').modal('hide');
  }

  onEditFichePatient(fiche) {
    this.editMode = true;
    $('#fichePatientFormModal').modal('show');
    this.fichePatientForm.get('nom').setValue(fiche.nom);
    this.fichierUrl = fiche.lien ? fiche.lien : '';
    const index = this.fichesPatient.findIndex((ficheEl) => {
      if (ficheEl === fiche) {
        return true;
      }
    });
    this.indexToUpdate = index;
  }

  onEditFicheKine(fiche) {
    this.editMode = true;
    $('#ficheKineFormModal').modal('show');
    this.fichesKineForm.get('nom').setValue(fiche.nom);
    this.fichierUrl = fiche.lien ? fiche.lien : '';
    const index = this.fichesKine.findIndex((ficheEl) => {
      if (ficheEl === fiche) {
        return true;
      }
    });
    this.indexToUpdate = index;
  }

  onEditRessource(ressource) {
    this.editMode = true;
    $('#ressourcesFormModal').modal('show');
    this.ressourcesForm.get('nom').setValue(ressource.nom);
    this.fichierUrl = ressource.lien ? ressource.lien : '';
    const index = this.ressources.findIndex((ressourceEl) => {
      if (ressourceEl === ressource) {
        return true;
      }
    });
    this.indexToUpdate = index;
  }

  onUploadFile(event) {
    this.ficheUploading = true;
    this.ficheService.uploadFile(event.target.files[0]).then((url: string) => {
      if (this.fichierUrl && this.fichierUrl !== '') {
        this.ficheService.removeFile(this.fichierUrl);
      }
      this.fichierUrl = url;
      this.ficheUploading = false;
      this.ficheUploaded = true;
      setTimeout(() => {
        this.ficheUploaded = false;
      }, 1000)
    }
    );
  }

  onUploadFileRessource(event) {
    this.ficheUploading = true;
    this.ressourcesService.uploadFile(event.target.files[0]).then((url: string) => {
      if (this.fichierUrl && this.fichierUrl !== '') {
        this.ressourcesService.removeFile(this.fichierUrl);
      }
      this.fichierUrl = url;
      this.ficheUploading = false;
      this.ficheUploaded = true;
      setTimeout(() => {
        this.ficheUploaded = false;
      }, 1000)
    }
    );
  }

  resetFormPatient() {
    this.editMode = false;
    this.fichePatientForm.reset();
    this.fichierUrl = '';
  }

  resetFormKine() {
    this.editMode = false;
    this.fichesKineForm.reset();
    this.fichierUrl = '';
  }

  resetFormRessources() {
    this.editMode = false;
    this.ressourcesForm.reset();
    this.fichierUrl = '';
  }

  onActivateLinkPatient(i){
      if (this.linkActivatePatient[i]){
        this.linkActivatePatient[i] = false;
      }else{
        this.linkActivatePatient[i] = true;
      }
  }

  onActivateLinkKine(i){
    if (this.linkActivateKine[i]){
      this.linkActivateKine[i] = false;
    }else{
      this.linkActivateKine[i] = true;
    }
}

onActivateLinkRessource(i){
  if (this.linkActivateRessource[i]){
    this.linkActivateRessource[i] = false;
  }else{
    this.linkActivateRessource[i] = true;
  }
}

  ngOnDestroy() {
    this.fichePatientSubscription.unsubscribe();
    this.fichesKineSubscription.unsubscribe();
    this.ressourcesSubscription.unsubscribe();
  }

}
