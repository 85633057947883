import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthentificationService } from '../services/authentification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm : FormGroup;

  constructor(private formBuilder : FormBuilder,
              private authentificationService : AuthentificationService,
              private router:Router) { }

  ngOnInit(){
    this.initLoginForm();
  }

  initLoginForm(){
    this.loginForm = this.formBuilder.group({
      email:['', [Validators.required, Validators.email]],
      password:['',[Validators.required, Validators.pattern(/[a-zA-A0-9.]{6,}/)]]
    })
  }

  onSubmitLoginForm(){
    const email = this.loginForm.get('email').value;
    const password = this.loginForm.get('password').value;
    this.authentificationService.signInUser(email, password).then((data)=>{
      this.router.navigate(['/admin']);
    }).catch((error)=>{
      console.log(error);
    });
  }



}
