
<div class="row">
  <div class="col-12  ">
    <div class="pb-3 text-center border">

      <h3 class="mb-4">Rappel</h3>
      <h6>Mettre un caractere en gras</h6>
      <p class="mb-4">&lt;strong&gt; exemple &lt;/strong&gt; => <strong>exemple</strong></p>
      <h6>Saut de ligne obligatoire</h6>
      <p class="mb-4">exemple &lt;br&gt; exemple => exemple <br> exemple</p>

  </div>

</div>



  <form [formGroup]="contenusForm" (ngSubmit)="onSubmitContenus()">

    <div class="col-12 text-center m-2"  >
      <button class="btn btn-success" type="submit"> modifier</button>
    </div>



<div class="input-group my-2">
  <div class="input-group-prepend">
    <span class="input-group-text">Accueil</span>
  </div>
  <textarea class="form-control" aria-label="With textarea" formControlName="acceuil"></textarea>
</div>

<div class="input-group my-3">
  <div class="input-group-prepend">
    <span class="input-group-text">Fiche Kiné</span>
  </div>
  <textarea class="form-control" aria-label="With textarea" formControlName="ficheKine" ></textarea>
</div>

<div class="input-group my-3 grand">
  <div class="input-group-prepend">
    <span class="input-group-text">Fiche Patient</span>
  </div>
  <textarea class="form-control" aria-label="With textarea" formControlName="fichePatient"></textarea>
</div>

<div class="input-group my-3 grand">
  <div class="input-group-prepend">
    <span class="input-group-text">Ressources</span>
  </div>
  <textarea class="form-control" aria-label="With textarea" formControlName="ressources"></textarea>
</div>

<div class="input-group my-3 grand">
  <div class="input-group-prepend">
    <span class="input-group-text">Qui comme nous ?</span>
  </div>
  <textarea class="form-control" aria-label="With textarea" formControlName="presentation" ></textarea>
</div>

<div class="input-group my-3 pb-5 grand">

</div>


  </form>
</div>














<!-- <div class="rounded shadow bg-white p-5">
  <div class="row">
    <div class="col-12 d-flex">
      <h2 class="font-weight-light"><i class="fas fa-running"></i>Membres</h2>
      <button class="btn btn-secondary ml-auto" data-toggle="modal" data-target="#membreFormModal" (click)="resetFormMembre()" >Ajouter un membre</button>
    </div>
  </div>
  <hr class="mt-1 mb-5 bg-dark">
  <div class="row">
    <div class="card-deck">
      <div class="card" style="width: 18rem;"  *ngFor="let membre of membres; let i = index">
        <img [src]="membre.photo | safe" class="card-img-top" style="width: auto; height: 200px;" *ngIf="membre.photo && membre.photo !== ''">
        <div class="card-body">
          <h5 class="card-title">{{membre.nom}}</h5>
          <p class="card-text">{{membre.diplome}}</p>
          <p>{{membre.structure}}</p>
          <p *ngIf="membre.orientation && membre.orientation !== ''">{{membre.orientation}}</p>
          <button type="button" class="btn btn-secondary m-1 ml-auto" (click)="onEditMembre(membre)"><i class="fas fa-edit"></i></button>
          <button type="button" class="btn btn-danger m-1 " (click)="onDeleteMembre(i)" ><i class="fas fa-trash-alt"></i></button>
        </div>
      </div>
    </div>
    </div>


    <!-- Modal Form Membre-->
    <!-- <div class="modal fade" id="membreFormModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form [formGroup]="membreForm" (ngSubmit)="onSubmitMembre()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">création d'une fiche</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="nom" formControlName="nom">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="diplome" formControlName="diplome">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="structure" formControlName="structure">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="orientation" formControlName="orientation">
              </div>
              <div *ngIf="fichierUrl && fichierUrl !== ''">
                <img [src]="fichierUrl | safe" width="100px" height="100px">
              </div>
           <div class="form-row">
             <div class="col-9">
              <div class="input-group mb-3">
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onUploadFile($event)">
                  <label class="custom-file-label" for="inputGroupFile01">Envoyer la fiche</label>
                </div>
              </div>
             </div>
             <div class="col-3">
              <div class="spinner-border" role="status" *ngIf="ficheUploading">
                <span class="sr-only">Loading...</span>
              </div>
             </div>
           </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="submit" class="btn btn-primary" [disabled]="membreForm.invalid">Enregistrer</button>
            </div>
          </form>
        </div>
      </div>
    </div> -->

    <!-- Modal Supression Membre-->
<!-- <div class="modal fade" id="deleteMembre" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">Supprimer ce membre?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-danger" (click)="onConfirmDeleteMembre()">Supprimer</button>
      </div>
    </div>
  </div>
</div> -->
