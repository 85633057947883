<div class="rounded shadow bg-white p-5">
  <div class="row">
    <div class="col-12 d-flex">
<!-- FICHE PATIENT -->
      <h2 class="font-weight-light"><i class="fas fa-running"></i> Liste Fiches patients</h2>
      <button class="btn btn-primary ml-auto" data-toggle="modal" data-target="#fichePatientFormModal" (click)="resetFormPatient()" >Aujouter une fiche patient</button>
    </div>
  </div>
  <hr class="mt-1 mb-5 bg-dark">
  <div class="row">
    <div  *ngFor="let fiche of fichesPatient; let i = index" class="col-12 shadow-sm mb-3">
      <div class="d-flex">
        <h3 class="font-weight-normal">{{fiche.nom}}</h3>
        <a class="ml-4 mt-2 " (click)="onActivateLinkPatient(i)">afficher le pdf</a>
        <button type="button" class="btn btn-secondary m-1 ml-auto" (click)="onEditFichePatient(fiche)"><i class="fas fa-edit"></i></button>
        <button type="button" class="btn btn-danger m-1 " (click)="onDeleteFichePatient(i)" ><i class="fas fa-trash-alt"></i></button>
      </div>
      <iframe [src]="fiche.lien | safe" width="100%" height="500px" *ngIf="linkActivatePatient[i] && linkActivatePatient[i] != false">
      </iframe>
    </div>
<!-- FICHE KINE -->
    <div class="col-12 d-flex mt-4">
      <h2 class="font-weight-light"><i class="fas fa-running"></i> Liste Fiches Kiné</h2>
      <button class="btn btn-primary ml-auto" data-toggle="modal" data-target="#ficheKineFormModal" (click)="resetFormKine()" >Aujouter une fiche Kiné</button>
    </div>
  </div>
  <hr class="mt-1 mb-5 bg-dark">
  <div class="row">
    <div  *ngFor="let fiche of fichesKine; let i = index" class="col-12 shadow-sm mb-3">
      <div class="d-flex">
        <h3 class="font-weight-normal">{{fiche.nom}}</h3>
        <a class="ml-4 mt-2" (click)="onActivateLinkKine(i)">afficher le pdf</a>
        <button type="button" class="btn btn-secondary m-1 ml-auto" (click)="onEditFicheKine(fiche)"><i class="fas fa-edit"></i></button>
        <button type="button" class="btn btn-danger m-1 " (click)="onDeleteFicheKine(i)" ><i class="fas fa-trash-alt"></i></button>
      </div>
      <iframe [src]="fiche.lien | safe" width="100%" height="500px" *ngIf="linkActivateKine[i] && linkActivateKine[i] != false">
      </iframe>
    </div>
  </div>
  <!-- RESSOURCES -->
  <div class="col-12 d-flex mt-4">
    <h2 class="font-weight-light"><i class="fas fa-running"></i> Ressources </h2>
    <button class="btn btn-primary ml-auto" data-toggle="modal" data-target="#ressourceFormModal" (click)="resetFormRessources()" >Aujouter une ressource</button>
  </div>

<hr class="mt-1 mb-5 bg-dark">
<div class="row">
  <div  *ngFor="let ressource of ressources; let i = index" class="col-12 shadow-sm mb-3">
    <div class="d-flex">
      <h3 class="font-weight-normal">{{ ressource.nom }}</h3>
      <a class="ml-4 mt-2" (click)="onActivateLinkRessource(i)">afficher le pdf</a>
      <button type="button" class="btn btn-secondary m-1 ml-auto" (click)="onEditRessource(ressource)"><i class="fas fa-edit"></i></button>
      <button type="button" class="btn btn-danger m-1 " (click)="onDeleteRessource(i)" ><i class="fas fa-trash-alt"></i></button>
    </div>
    <iframe [src]="ressource.lien | safe" width="100%" height="500px" *ngIf="linkActivateRessource[i] && linkActivateRessource[i] != false">
    </iframe>
  </div>
</div>

</div>


<!-- Modal exercices patients -->
<div class="modal fade" id="fichePatientFormModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <form [formGroup]="fichePatientForm" (ngSubmit)="onSubmitFichePatient()">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">création d'une fiche</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="nom" formControlName="nom">
          </div>
          <div *ngIf="fichierUrl && fichierUrl !== ''">
            <iframe [src]="fichierUrl | safe" width="100%" height="500px">
            </iframe>
          </div>
       <div class="form-row">
         <div class="col-9">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onUploadFile($event)">
              <label class="custom-file-label" for="inputGroupFile01">Envoyer la fiche</label>
            </div>
          </div>
         </div>
         <div class="col-3">
          <div class="spinner-border" role="status" *ngIf="ficheUploading">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
       </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
          <button type="submit" class="btn btn-primary" [disabled]="fichePatientForm.invalid">Enregistrer</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal exercices Kine -->
<div class="modal fade" id="ficheKineFormModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <form [formGroup]="fichesKineForm" (ngSubmit)="onSubmitFichesKine()">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">création d'une fiche</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="nom" formControlName="nom">
          </div>
          <div *ngIf="fichierUrl && fichierUrl !== ''">
            <iframe [src]="fichierUrl | safe" width="100%" height="500px">
            </iframe>
          </div>
       <div class="form-row">
         <div class="col-9">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onUploadFile($event)">
              <label class="custom-file-label" for="inputGroupFile01">Envoyer la fiche</label>
            </div>
          </div>
         </div>
         <div class="col-3">
          <div class="spinner-border" role="status" *ngIf="ficheUploading">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
       </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
          <button type="submit" class="btn btn-primary" [disabled]="fichesKineForm.invalid">Enregistrer</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Ressources -->
<div class="modal fade" id="ressourceFormModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <form [formGroup]="ressourcesForm" (ngSubmit)="onSubmitRessources()">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">Ajout d'une ressource</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="nom" formControlName="nom">
          </div>
          <div *ngIf="fichierUrl && fichierUrl !== ''">
            <iframe [src]="fichierUrl | safe" width="100%" height="500px">
            </iframe>
          </div>
       <div class="form-row">
         <div class="col-9">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onUploadFileRessource($event)">
              <label class="custom-file-label" for="inputGroupFile01">Envoyer la ressource</label>
            </div>
          </div>
         </div>
         <div class="col-3">
          <div class="spinner-border" role="status" *ngIf="ficheUploading">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
       </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
          <button type="submit" class="btn btn-primary" [disabled]="ressourcesForm.invalid">Enregistrer</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Supression Patient-->
<div class="modal fade" id="deleteModalPatient" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">Supprimer cette fiche ?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-danger" (click)="onConfirmDeleteFichePatient()">Supprimer</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Supression Kine-->
<div class="modal fade" id="deleteModalKine" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">Supprimer cette fiche ?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-danger" (click)="onConfirmDeleteFicheKine()">Supprimer</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Supression Ressource-->
<div class="modal fade" id="deleteModalRessource" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">Supprimer cette ressource ?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-danger" (click)="onConfirmDeleteRessource()">Supprimer</button>
      </div>
    </div>
  </div>
</div>
