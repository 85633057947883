import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContenusService } from '../services/contenus.service';
import { Subscription } from 'rxjs';
import { Contenus } from '../interface/contenus';

@Component({
  selector: 'app-acceuil',
  templateUrl: './acceuil.component.html',
  styleUrls: ['./acceuil.component.scss']
})
export class AcceuilComponent implements OnInit, OnDestroy {

  contenus:Contenus;

  contenusSubscription : Subscription;


  constructor(private contenusService : ContenusService) { }

  ngOnInit(): void {
    this.contenusSubscription = this.contenusService.contenusSubject.subscribe((data: Contenus) => {
      this.contenus = data;
    });
    this.contenusService.getContenus();
    this.contenusService.emitContenus();


}


ngOnDestroy(){

}

}
