import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Contenus } from '../interface/contenus';
import { ContenusService } from '../services/contenus.service';


@Component({
  selector: 'app-qui-sommes-nous',
  templateUrl: './qui-sommes-nous.component.html',
  styleUrls: ['./qui-sommes-nous.component.scss']
})
export class QuiSommesNousComponent implements OnInit  {


  contenus: Contenus;
  contenusSubscription : Subscription;
  constructor(private contenusService : ContenusService){}

  ngOnInit(){
    this.contenusSubscription = this.contenusService.contenusSubject.subscribe((data : Contenus)=>{
      this.contenus = data;
      if(this.contenus){
        document.getElementById("emplacement").innerHTML = this.contenus.presentation
        }
    })
    this.contenusService.getContenus();
    this.contenusService.emitContenus();
}

}
