<h1 class="display-4 mt-5 mb-0">Authentification</h1>
<hr class="mt-0 mb-5 bg-dark">
<div class="row">
  <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmitLoginForm()">
      <div class="form-group">
        <label for="email">Adresse email</label>
        <input type="email" id="email" class="form-control" formControlName="email">
      </div>
      <div class="form-group">
        <label for="password">Mot de passe</label>
        <input type="password" id="password" class="form-control" formControlName="password">
      </div>
      <button class="btn btn-secondary" type="submit" [disabled]='loginForm.invalid'>Connexion</button>

    </form>
  </div>
</div>
