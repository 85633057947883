import { Component, OnInit } from '@angular/core';
import { Fiche } from '../interface/fiche';
import { Subscription } from 'rxjs';
import { Contenus } from '../interface/contenus';
import { ContenusService } from '../services/contenus.service';
import { RessourcesService } from '../services/ressources.service';

@Component({
  selector: 'app-ressources',
  templateUrl: './ressources.component.html',
  styleUrls: ['./ressources.component.scss']
})
export class RessourcesComponent implements OnInit {

  ressources: Fiche[] = [];
  contenus: Contenus;

  ressourcesSubscription: Subscription;
  contenusSubscription : Subscription;
  linkActivateRessource: boolean[] = [];

  constructor(private ressourcesService: RessourcesService,
              private contenusService : ContenusService) { }

              ngOnInit(): void {
                this.ressourcesSubscription = this.ressourcesService.ressourcesSubject.subscribe((data: Fiche[]) => {
                  this.ressources = data;
                });
                this.contenusSubscription = this.contenusService.contenusSubject.subscribe((data : Contenus)=>{
                  this.contenus = data;
                  if(this.contenus){
                    document.getElementById("emplacement").innerHTML = this.contenus.ressources
                    }
                  })
                this.ressourcesService.getRessources();
                this.ressourcesService.emitRessources();
                this.contenusService.getContenus();
                this.contenusService.emitContenus();
              }
              onActivateLinkRessource(i) {
                if (this.linkActivateRessource[i]) {
                  this.linkActivateRessource[i] = false;
                } else {
                  this.linkActivateRessource[i] = true;
                }
              }


              ngOnDestroy() {
                this.ressourcesSubscription.unsubscribe();
                this.contenusSubscription.unsubscribe();
              }
            }


