import { Injectable } from '@angular/core';
import { Fiche } from '../interface/fiche';
import { Observable, Subject } from 'rxjs';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FicheService {

  fichesPatient: Fiche[] =[];
  fichesKine: Fiche[] =[];

  fichesPatientSubject = new Subject<Fiche[]>();
  fichesKineSubject = new Subject<Fiche[]>();

  constructor() { }

  emitFichePatient() {
    this.fichesPatientSubject.next(this.fichesPatient);
  }

  emitFicheKine() {
    this.fichesKineSubject.next(this.fichesKine);
  }

  saveFichePatient() {
    firebase.database().ref('/fiches/fichesPatient').set(this.fichesPatient);
  }

  saveFicheKine() {
    firebase.database().ref('/fiches/fichesKine').set(this.fichesKine);
  }

  getFichesPatient(){
    firebase.database().ref('fiches/fichesPatient').on('value',(data)=>{
      this.fichesPatient = data.val() ? data.val() : [];
      this.emitFichePatient();
    });
  }

  getFichesKine(){
    firebase.database().ref('fiches/fichesKine').on('value',(data)=>{
      this.fichesKine = data.val() ? data.val() : [];
      this.emitFicheKine();
    });
  }

  createFichePatient(fiche: Fiche) {
    this.fichesPatient.push(fiche);
    this.saveFichePatient();
    this.emitFichePatient();
  }

  createFicheKine(fiche: Fiche) {
    this.fichesKine.push(fiche);
    this.saveFicheKine();
    this.emitFicheKine();
  }

  updateFichePatient(fiche, index) {
    this.fichesPatient[index] = fiche;
    this.saveFichePatient();
    this.emitFichePatient();
  }

  updateFicheKine(fiche, index) {
    this.fichesKine[index] = fiche;
    this.saveFicheKine();
    this.emitFicheKine();
  }


  deleteFichePatient(index) {
    this.fichesPatient.splice(index, 1);
    this.saveFichePatient();
    this.emitFichePatient();
  }

  deleteFicheKine(index) {
    this.fichesKine.splice(index, 1);
    this.saveFicheKine();
    this.emitFicheKine();
  }

  uploadFile(file:File){
    return new Promise((resolve, reject)=>{
      const upload= firebase.storage().ref().child('pdf/fiches/' + file.name).put(file);
      upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
        ()=>{
          console.log('chargement');
        },(error)=>{
          console.error(error);
          reject(error);
        },()=>{
          upload.snapshot.ref.getDownloadURL().then((downloadUrl)=>{
            resolve(downloadUrl);
          });
        }
        );
    })
  }

  removeFile(fileLink: string){
    if(fileLink){
      const storageRef = firebase.storage().refFromURL(fileLink);
      storageRef.delete().then(()=>{
        console.log('File deleted');
      }).catch((error)=>{
        console.error(error);
      });
    }
  }

}
