import { Component} from '@angular/core';
import * as firebase from 'firebase';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'site-kine';

  constructor(){
    const firebaseConfig = {
      apiKey: "AIzaSyDZgxDlmHPqArF9D_ncKWqNqqKH_uWku4w",
      authDomain: "cpcp-kine.firebaseapp.com",
      databaseURL: "https://cpcp-kine.firebaseio.com",
      projectId: "cpcp-kine",
      storageBucket: "cpcp-kine.appspot.com",
      messagingSenderId: "114226359182",
      appId: "1:114226359182:web:024076aaf9ed3fa9b6d2df",
      measurementId: "G-83X323FFRH"
    };
    firebase.initializeApp(firebaseConfig);

  }
}
