<div class="container mb-5 rounded shadow bg-white p-4" style="min-height: 90vh" >
  <h1 class="text-center my-4">Fiches Kiné</h1>
  <p id="emplacement" class="text-center"  >


  </p>
  <br><br>
  <div *ngFor="let fiche of fichesKine; let i = index">
    <div class="row">
      <div class="col-12 d-flex">
        <h3>{{fiche.nom}}</h3>
        <button class="ml-4 btn btn-info" (click)="onActivateLinkKine(i)"
        *ngIf="!linkActivateKine[i] || linkActivateKine[i] === false">Afficher le pdf</button>

        <button class="btn btn-warning ml-4" (click)="onActivateLinkKine(i)"
        *ngIf="linkActivateKine[i] && linkActivateKine[i] != false">Masquer le pdf</button>
      </div>
    </div>
    <iframe [src]="fiche.lien | safe" width="100%" height="500px" class="mt-3"
      *ngIf="linkActivateKine[i] && linkActivateKine[i] != false">
    </iframe>
    <hr>
  </div>

  <div *ngIf="fichesKine == []"> Il n'y a pas de fiche pour le moment !
  </div>
</div>
