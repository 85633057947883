import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate{

  constructor(private router : Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve,reject)=>{
      firebase.auth().onAuthStateChanged((userSession)=>{
        if (userSession){
          resolve(true);
        }else {
          this.router.navigate(['/acceuil']);
          resolve(false);
        }
      })
    })

  }
}

