import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import{ FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AcceuilComponent } from './acceuil/acceuil.component';
import { QuiSommesNousComponent } from './qui-sommes-nous/qui-sommes-nous.component';
import { LoginComponent } from './login/login.component';
import { AuthguardService } from './services/authguard.service';
import { FicheService } from './services/fiche.service';
import { AdminListExerciceComponent } from './admin/admin-list-exercice/admin-list-exercice.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { SafePipe } from './safe.pipe';
import { AdminContenusComponent } from './admin/admin-contenus/admin-contenus.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FichesPatientComponent } from './fiches-patient/fiches-patient.component';
import { FichesKineComponent } from './fiches-kine/fiches-kine.component';
import { ContenusService } from './services/contenus.service';
import { RessourcesComponent } from './ressources/ressources.component';
import { RessourcesService } from './services/ressources.service';

@NgModule({
  declarations: [
    AppComponent,
    AcceuilComponent,
    QuiSommesNousComponent,
    LoginComponent,
    AdminListExerciceComponent,
    AdminDashboardComponent,
    SafePipe,
    AdminContenusComponent,
    HeaderComponent,
    FooterComponent,
    FichesPatientComponent,
    FichesKineComponent,
    RessourcesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthguardService,
    FicheService,
    ContenusService,
    RessourcesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
