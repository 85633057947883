import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AcceuilComponent } from './acceuil/acceuil.component';
import { LoginComponent } from './login/login.component';
import { QuiSommesNousComponent } from './qui-sommes-nous/qui-sommes-nous.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AuthguardService } from './services/authguard.service';
import { FichesPatientComponent } from './fiches-patient/fiches-patient.component';
import { FichesKineComponent } from './fiches-kine/fiches-kine.component';
import { RessourcesComponent } from './ressources/ressources.component';


const routes: Routes = [
  {path: 'acceuil', component : AcceuilComponent },
  {path: 'fiches/patient', component : FichesPatientComponent},
  {path: 'fiches/kine', component : FichesKineComponent},
  {path: 'presentation', component:QuiSommesNousComponent},
  {path: 'login', component : LoginComponent},
  {path: 'ressources', component : RessourcesComponent},

  {path: 'admin',canActivate : [AuthguardService], component : AdminDashboardComponent},
  {path: '', redirectTo : '/acceuil', pathMatch: 'full'},
  {path: '**', redirectTo : '/acceuil' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
