<div id="img">
  <div class="container">
    <div class="row">
      <div class="col mt-1" >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/cpcp-kine.appspot.com/o/logo%20CPCP.png?alt=media&token=05a8baef-2821-456b-9b5f-d7f3c86b59f0"
          alt="">
      </div>
    </div>
    <div class="row">
      <div class="col img">
        <p *ngIf="contenus"
            class="text-center mt-5"
            style="font-size: 1.7em;"> {{ contenus.acceuil }} </p>
      </div>
    </div>
  </div>
</div>
