<div class="container">
<div class="rounded shadow bg-white p-4" style="min-height: 90vh">
  <div class="row">
    <div class="col-12 d-flex">
      <h1 class="font-weight-light">Presentation du CPCP</h1>
    </div>
  </div>
  <hr class="mt-1 mb-5 bg-dark">

  <h2 class="text-center">Qu'est ce que le CPCP ?</h2>

  <p class="m-4"  id="emplacement">

  </p>
</div>
</div>
