import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as firebase from 'firebase';
import { Fiche } from '../interface/fiche';

@Injectable({
  providedIn: 'root'
})
export class RessourcesService {

  ressources: Fiche[] =[];
  ressourcesSubject = new Subject<Fiche[]>();

  constructor() { }

  emitRessources() {
    this.ressourcesSubject.next(this.ressources);
  }

  saveRessources() {
    firebase.database().ref('/ressources').set(this.ressources);
  }



  getRessources(){
    firebase.database().ref('ressources').on('value',(data)=>{
      this.ressources = data.val() ? data.val() : [];
      this.emitRessources();
    });
  }


  createRessource(fiche: Fiche) {
    this.ressources.push(fiche);
    this.saveRessources();
    this.emitRessources();
  }


  updateRessources(fiche, index) {
    this.ressources[index] = fiche;
    this.saveRessources();
    this.emitRessources();
  }

  deleteRessources(index) {
    this.ressources.splice(index, 1);
    this.saveRessources();
    this.emitRessources();
  }


  uploadFile(file:File){
    return new Promise((resolve, reject)=>{
      const upload= firebase.storage().ref().child('pdf/ressources/' + file.name).put(file);
      upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
        ()=>{
          console.log('chargement');
        },(error)=>{
          console.error(error);
          reject(error);
        },()=>{
          upload.snapshot.ref.getDownloadURL().then((downloadUrl)=>{
            resolve(downloadUrl);
          });
        }
        );
    })
  }

  removeFile(fileLink: string){
    if(fileLink){
      const storageRef = firebase.storage().refFromURL(fileLink);
      storageRef.delete().then(()=>{
        console.log('File deleted');
      }).catch((error)=>{
        console.error(error);
      });
    }
  }

}
