<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse " id="navbarNavDropdown">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item marge" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/acceuil']" routerLinkActive="router-link-active" >Accueil</a>
      </li>
      <li class="nav-item dropdown marge">
        <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Fiches
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" routerLinkActive="active">
          <a class="dropdown-item" [routerLink]="['/fiches/patient']" routerLinkActive="active" >Fiches patients</a>
          <a class="dropdown-item" [routerLink]="['/fiches/kine']" routerLinkActive="active" >Fiches Kiné</a>
          <a class="dropdown-item" [routerLink]="['/ressources']" routerLinkActive="active" >Ressources</a>
        </div>
      </li>
      <li class="nav-item marge " routerLinkActive="active" >
        <a class="nav-link" [routerLink]="['/presentation']" routerLinkActive="router-link-active" >Qui sommes nous ?</a>
      </li>
      <li class="nav-item dropdown" *ngIf="isLoggedIn">
        <a href="" class="nav-link dropdown-toggle" id="navbar-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Administration
        </a>
        <div class="dropdown-menu " aria-labelledby="navbarDropdownMenuLink" routerLinkActive="active">
          <a class="dropdown-item" [routerLink]="['/admin']" routerLinkActive="router-link-active"  >Tableau de bord</a>
          <a class="dropdown-item text-danger" (click)="logOut()" >Deconnexion</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
