import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as firebase from 'firebase';
import { Contenus } from '../interface/contenus';

@Injectable({
  providedIn: 'root'
})
export class ContenusService {

  contenus : Contenus;

  contenusSubject = new Subject<Contenus>();

  constructor() { }

  emitContenus() {
    this.contenusSubject.next(this.contenus);
  }

  getContenus(){
    firebase.database().ref('contenus').on('value',(data)=>{
      this.contenus = data.val() ? data.val() : '';
      this.emitContenus();
    });
  }

  saveContenus() {
    firebase.database().ref('/contenus').set(this.contenus);
  }

  updateContenus(contenus) {
    this.contenus = contenus;
    this.saveContenus();
    this.emitContenus();
  }

}
