import { Component, OnInit } from '@angular/core';
import { Fiche } from '../interface/fiche';
import { Subscription } from 'rxjs';
import { FicheService } from '../services/fiche.service';
import { ContenusService } from '../services/contenus.service';
import { Contenus } from '../interface/contenus';

@Component({
  selector: 'app-fiches-kine',
  templateUrl: './fiches-kine.component.html',
  styleUrls: ['./fiches-kine.component.scss']
})
export class FichesKineComponent implements OnInit {

  fichesKine: Fiche[] = [];
  linkActivateKine: boolean[] = [];
  contenus: Contenus;

  fichesKineSubscription: Subscription;
  contenusSubscription : Subscription;

  constructor(private ficheService: FicheService,
              private contenusService : ContenusService) { }

  ngOnInit(){
    this.fichesKineSubscription = this.ficheService.fichesKineSubject.subscribe((data: Fiche[]) => {
      this.fichesKine = data;
    });
    this.contenusSubscription = this.contenusService.contenusSubject.subscribe((data : Contenus)=>{
      this.contenus = data;
      if(this.contenus){
        document.getElementById("emplacement").innerHTML = this.contenus.ficheKine
        }
    })
    this.ficheService.getFichesKine();
    this.ficheService.emitFicheKine();
    this.contenusService.getContenus();
    this.contenusService.emitContenus();


  }

  onActivateLinkKine(i) {
    if (this.linkActivateKine[i]) {
      this.linkActivateKine[i] = false;
    } else {
      this.linkActivateKine[i] = true;
    }
  }

  ngOnDestroy() {
    this.fichesKineSubscription.unsubscribe();
    this.contenusSubscription.unsubscribe();
  }
}
